import request from './request.js';

export default {
  compositionList(_data) {
    //考试-练习列表
    return request({
      url: '/system/exam/compositionList?data=' + JSON.stringify(_data),
      method: 'get',
    });
  },

  questionCategoryList(_data) {
    //考卷分类列表
    return request({
      url: '/system/exam/questionCategoryList?data=' + JSON.stringify(_data),
      method: 'get',
    });
  },

  getQuestion(_data) {
    //题库下的试题列表
    return request({
      url: '/system/exam/getQuestion?data=' + JSON.stringify(_data),
      method: 'get',
    });
  },

  addinsertQuestion(_data) {
    //添加试题
    return request({
      url: '/system/exam/insertQuestion?data=' + JSON.stringify(_data),
      method: 'post',
    });
  },

  deleteQuestion(_data) {
    //删除试题
    return request({
      url: '/system/exam/deleteQuestion?data=' + JSON.stringify(_data),
      method: 'post',
    });
  },

  questionList(_data) {
    //题库列表
    return request({
      url: '/system/exam/questionList?data=' + JSON.stringify(_data),
      method: 'get',
    });
  },

  themequestionUpdate(_data) {
    //题库添加 修改
    return request({
      url: '/system/exam/questionUpdate?data=' + JSON.stringify(_data),
      method: 'post',
    });
  },

  themequestionDelete(_data) {
    //题库删除
    return request({
      url: '/system/exam/questionDelete?data=' + JSON.stringify(_data),
      method: 'post',
    });
  },

  questionCateUpdate(_data) {
    //考卷分类添加 修改
    return request({
      url: '/system/exam/questionCateUpdate?data=' + JSON.stringify(_data),
      method: 'post',
    });
  },

  questionCateDelete(_data) {
    //考卷分类删除
    return request({
      url: '/system/exam/questionCateDelete?data=' + JSON.stringify(_data),
      method: 'post',
    });
  },

  insertComposition(_data) {
    //添加考试-练习
    return request({
      url: '/system/exam/insertComposition?data=' + JSON.stringify(_data),
      method: 'post',
    });
  },

  deleteComposition(_data) {
    //删除考试-练习
    return request({
      url: '/system/exam/deleteComposition?data=' + JSON.stringify(_data),
      method: 'post',
    });
  },

  getSonCourseList(_data) {
    //获取关联课程
    return request({
      url: '/system/course/getSonCourseList?data=' + JSON.stringify(_data),
      method: 'get',
    });
  },
  gettemplateList(_data) {
    //获取关联证书
    return request({
      url: '/system/template/templateList?data=' + JSON.stringify(_data),
      method: 'get',
    });
  },
  getCompositionDetailDto(_data) {
    //考试-练习详情
    return request({
      url: '/system/exam/getCompositionDetailDto?data=' + JSON.stringify(_data),
      method: 'get',
    });
  },
  getUserCompositionListss(_data) {
    //试题下用户考试记录
    return request({
      url: '/system/exam/getUserCompositionList?data=' + JSON.stringify(_data),
      method: 'get',
    });
  },

  exportQuestionsTemplate(_data) {
    //导出用户提现列表
    return request({
      url:
        '/system/exam/exportQuestionsTemplate?data=' +
        JSON.stringify(_data),
      method: 'get',
      responseType: 'blob',
    });
  },


};
