export default {
  qiniuUrl: 'http://qiniu.banluyuan.com/',

  qiniuUploadUrl: 'https://upload-z1.qiniup.com',

  uploadUrl: '/api/system/upload/uploadList',

  // 门店状态，1注册未填写资料、2审核中、3审核失败、4审核成功已完善资料   5账号冻结 6审核成功未完善资料

  muchType: { 1: '抵现金抵扣', 2: '送礼金', 3: '兑换梦响金' }, //商品、套餐优惠类型

  orderType: { 1: '普通订单', 2: '积分订单', 3: '拼团订单', 4: '秒杀订单' }, //商品订单类型（全部）

  serviceType: { 1: '线上电商', 2: '生活服务', 3: '精品课程' }, //店铺分类

  shopStatus: {
    1: '注册未填写资料',
    2: '审核中',
    3: '审核失败',
    4: '审核成功',
    5: '账号冻结',
    6: '审核成功未完善资料',
  }, //店铺状态

  goodsStatus: {
    1: '出售中',
    2: '仓库中',
    3: '待审核',
    4: '审核未通过',
    5: '强制下架',
  }, //商品状态

  goodsOrderStatus: {
    1: '待付款',
    2: '待发货',
    3: '待收货',
    4: '待评价',
    5: '已完成',
    6: '已关闭',
    7: '已关闭',
  }, //商品订单状态

  goodsRefundOrderStatus: {
    1: '待商家处理',
    2: '寄回商品',
    3: '带商户收货',
    4: '退款完成',
    5: '商家拒绝',
    6: '平台介入',
    7: '平台拒绝',
    8: '用户取消',
  }, //商品退款订单状态

  groupOrderStatus: { 1: '待拼单', 2: '拼团中', 3: '拼团成功', 4: '拼团失败' }, //拼团订单状态

  groupOrderType: { 1: '公开团', 2: '私有团' }, //拼团订单类型

  couponStatus: { 1: '使用中', 2: '失效', 3: '强制下架', 4: '停止发放' }, //优惠券状态

  couponType: { 1: '电商', 2: '课程', 3: '本地生活' }, //优惠券类型

  levelType: { 1: '心理咨询师', 2: '心理医生', 3: '健康管理师' }, //咨询师类型

  sex: { 1: '男', 2: '女' },

  consultOrderStatus: {
    1: '待支付',
    2: '待接单',
    3: '待咨询',
    4: '待确认',
    5: '待评价',
    6: '已完成',
    7: '已取消',
    8: '已关闭',
    9: '已拒绝',
  }, //咨询订单状态

  testOrderStatus: { 1: '待评测', 2: '已评测', 3: '已完成' }, //测评订单状态
  refundStatusArr: {
    '-1': '用户取消',
    1: '待商家处理',
    2: '寄回商品',
    3: '带商户收货',
    4: '退款完成',
    5: '商家拒绝',
    6: '平台介入',
    7: '平台拒绝',
  },
};
