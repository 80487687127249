import request from './request.js';

export default {
  newsList(_data) {
    //资讯列表
    return request({
      url: '/system/news/newsList?data=' + JSON.stringify(_data),
      method: 'get',
    });
  },
  categoryList(_data) {
    //分类列表
    return request({
      url: '/system/news/categoryList?data=' + JSON.stringify(_data),
      method: 'get',
    });
  },

  categoryInsert(_data) {
    //分类添加-修改
    return request({
      url: '/system/news/categoryInsert?data=' + JSON.stringify(_data),
      method: 'post',
    });
  },

  deleteCategory(_data) {
    //删除分类
    return request({
      url: '/system/news/deleteCategory?data=' + JSON.stringify(_data),
      method: 'post',
    });
  },
};
