import request from './request.js';

export default {
  // activityList(_data) {
  //   //活动列表
  //   return request({
  //     url: '/system/activity/activityList?data=' + JSON.stringify(_data),
  //     method: 'get',
  //   });
  // },

  // activityDetails(_data) {
  //   //活动详情
  //   return request({
  //     url: '/system/activity/activityDetails?data=' + JSON.stringify(_data),
  //     method: 'get',
  //   });
  // },

  // activityUserList(_data) {
  //   //活动详情活动核销人员列表
  //   return request({
  //     url:
  //       '/system/activity/activityDetailsByAuditUserList?data=' +
  //       JSON.stringify(_data),
  //     method: 'get',
  //   });
  // },

  // activityCateList(_data) {
  //   //活动分类列表
  //   return request({
  //     url: '/system/activity/activityCateList?data=' + JSON.stringify(_data),
  //     method: 'get',
  //   });
  // },

  // activityCateInsertOrUpdate(_data) {
  //   //活动分类添加-编辑
  //   return request({
  //     url:
  //       '/system/activity/activityCateInsertOrUpdate?data=' +
  //       JSON.stringify(_data),
  //     method: 'post',
  //   });
  // },

  // activityCateDelete(_data) {
  //   //活动分类删除
  //   return request({
  //     url: '/system/activity/activityCateDelete?data=' + JSON.stringify(_data),
  //     method: 'post',
  //   });
  // },

  // activityOrderList(_data) {
  //   //活动订单列表
  //   return request({
  //     url: '/system/activity/activityOrderList?data=' + JSON.stringify(_data),
  //     method: 'get',
  //   });
  // },

  // activityOrderDetails(_data) {
  //   //活动订单详情
  //   return request({
  //     url:
  //       '/system/activity/activityOrderDetails?data=' + JSON.stringify(_data),
  //     method: 'get',
  //   });
  // },

  // InsertAuditUserList(_data) {
  //   //添加活动核销人员
  //   return request({
  //     url: '/system/activity/InsertAuditUserList?data=' + JSON.stringify(_data),
  //     method: 'post',
  //   });
  // },

  // DeleteAuditUserList(_data) {
  //   //删除活动核销人员
  //   return request({
  //     url: '/system/activity/DeleteAuditUserList?data=' + JSON.stringify(_data),
  //     method: 'post',
  //   });
  // },

  // activityDetailsByOrderUserList(_data) {
  //   //活动详情报名列表
  //   return request({
  //     url:
  //       '/system/activity/activityDetailsByOrderUserList?data=' +
  //       JSON.stringify(_data),
  //     method: 'get',
  //   });
  // },

  // insertActivity(_data) {
  //   //添加活动
  //   return request({
  //     url: '/system/activity/insertActivity?data=' + JSON.stringify(_data),
  //     method: 'post',
  //   });
  // },
};
