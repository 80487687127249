import request from './request.js';

export default {
  initOrderPage(_data) {
    //线上订单列表
    return request({
      url: '/system/order/initOrderPage?data=' + JSON.stringify(_data),
      method: 'get',
    });
  },

  initRefundPage(_data) {
    //退款订单列表
    return request({
      url: '/system/order/initRefundPage?data=' + JSON.stringify(_data),
      method: 'get',
    });
  },

  orderDetail(_data) {
    //订单详情
    return request({
      url: '/system/order/orderDetail?data=' + JSON.stringify(_data),
      method: 'get',
    });
  },

  orderRefundDetail(_data) {
    //退款订单详情
    return request({
      url: '/system/order/orderRefundDetail?data=' + JSON.stringify(_data),
      method: 'get',
    });
  },

  submitAuditResult(_data) {
    //提交审核结果
    return request({
      url: '/system/order/submitAuditResult?data=' + JSON.stringify(_data),
      method: 'post',
    });
  },

  viewLogisticsInfo(_data) {
    //查看商品物流信息
    return request({
      url: '/system/order/viewLogisticsInfo?data=' + JSON.stringify(_data),
      method: 'get',
    });
  },

  initCommentPageForGoods(_data) {
    //商品评论
    return request({
      url:
        '/system/comment/initCommentPageForGoods?data=' + JSON.stringify(_data),
      method: 'get',
    });
  },

  auditComment(_data) {
    //商品评论审核
    return request({
      url: '/system/comment/auditComment?data=' + JSON.stringify(_data),
      method: 'post',
    });
  },

  deleteComment(_data) {
    //商品评论删除
    return request({
      url: '/system/comment/deleteComment?data=' + JSON.stringify(_data),
      method: 'post',
    });
  },

  goodsCategoryPage(_data) {
    //商品分类列表
    return request({
      url:
        '/system/goodsCategory/goodsCategoryPage?data=' + JSON.stringify(_data),
      method: 'get',
    });
  },

  updateGoodsCategory(_data) {
    //编辑分类
    return request({
      url:
        '/system/goodsCategory/updateGoodsCategory?data=' +
        JSON.stringify(_data),
      method: 'post',
    });
  },

  createGoodsCategory(_data) {
    //添加分类
    return request({
      url:
        '/system/goodsCategory/createGoodsCategory?data=' +
        JSON.stringify(_data),
      method: 'post',
    });
  },

  deleteGoodsCategory(_data) {
    //删除分类
    return request({
      url:
        '/system/goodsCategory/deleteGoodsCategory?data=' +
        JSON.stringify(_data),
      method: 'post',
    });
  },

  batchAuditGoods(_data) {
    //批量审核商品
    return request({
      url: '/system/goods/batchAuditGoods?data=' + JSON.stringify(_data),
      method: 'post',
    });
  },

  batchShelvesGoods(_data) {
    //商品批量通过或拒绝
    return request({
      url: '/system/goods/batchShelvesGoods?data=' + JSON.stringify(_data),
      method: 'post',
    });
  },

  createEditSpec(_data) {
    //（添加-编辑-删除）属性规格
    return request({
      url: '/system/goods/createEditSpec?data=' + JSON.stringify(_data),
      method: 'post',
    });
  },

  createGoods(_data, html) {
    //发布商品
    return request({
      url: '/system/goods/createGoods?data=' + JSON.stringify(_data),
      method: 'post',
      data: html,
    });
  },

  initGoodModelPage(_data) {
    //商品模型列表初始化
    return request({
      url: '/system/goods/initGoodModelPage?data=' + JSON.stringify(_data),
      method: 'get',
    });
  },

  initGoodsModelSpecPage(_data) {
    //初始化模型下的商品属性
    return request({
      url: '/system/goods/initGoodsModelSpecPage?data=' + JSON.stringify(_data),
      method: 'get',
    });
  },

  createGoodsModel(_data) {
    //添加-修改商品模型
    return request({
      url: '/system/goods/createGoodsModel?data=' + JSON.stringify(_data),
      method: 'post',
    });
  },

  removeGoodsType(_data) {
    //删除商品模型
    return request({
      url: '/system/goods/removeGoodsType?data=' + JSON.stringify(_data),
      method: 'post',
    });
  },

  deleteSpec(_data) {
    //删除规格、属性
    return request({
      url: '/system/goods/deleteSpec?data=' + JSON.stringify(_data),
      method: 'post',
    });
  },

  goodsDetailInfo(_data) {
    //商品详情
    return request({
      url: '/system/goods/goodsDetailInfo?data=' + JSON.stringify(_data),
      method: 'get',
    });
  },

  findGoodsDetail(_data) {
    //商品详情
    return request({
      url: '/system/goods/findGoodsDetail?data=' + JSON.stringify(_data),
      method: 'get',
    });
  },

  initGoodsList(_data) {
    //商品列表
    return request({
      url: '/system/goods/initGoodsList?data=' + JSON.stringify(_data),
      method: 'get',
    });
  },

  batchDelGoods(_data) {
    //批量删除商品
    return request({
      url: '/system/goods/batchDelGoods?data=' + JSON.stringify(_data),
      method: 'post',
    });
  },
};
