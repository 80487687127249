import request from './request.js'

export default {

    comboExcel(_data){  //套餐订单导出excel
        return request({
            url:'/system/localLifeOrder/excel?data='+ JSON.stringify(_data),
            method:'get',
            responseType: 'blob'
        })
    },

    editComboIsRecommend(_data){  //设置套餐是否推荐
        return request({
            url:'/system/shop-combo/editIsRecommend?data='+ JSON.stringify(_data),
            method:'post',
        })
    },

    initCommentPageForCombo(_data){  //套餐评价列表初始化
        return request({
            url:'/system/comment/initCommentPageForCombo?data='+ JSON.stringify(_data),
            method:'get',
        })
    },

    initComboPage(_data){  //套餐管理列表初始化
        return request({
            url:'/system/shop-combo/initComboPage?data='+ JSON.stringify(_data),
            method:'get',
        })
    },

    auditCombo(_data){  //审核套餐
        return request({
            url:'/system/shop-combo/auditCombo?data='+ JSON.stringify(_data),
            method:'post',
        })
    },

    editComboSort(_data){  //设置套餐排序
        return request({
            url:'/system/shop-combo/editSort?data='+ JSON.stringify(_data),
            method:'post',
        })
    },

    standUpAndDownCombo(_data){  //上下架套餐{idArr}
        return request({
            url:'/system/shop-combo/standUpAndDownCombo/'+ _data,
            method:'post',
        })
    },

    initLocalLifeOrderPage(_data){  // 线下订单列表
        return request({
            url:'/system/localLifeOrder/initLocalLifeOrderPage?data='+ JSON.stringify(_data),
            method:'get',
        })
    },

    localLifeOrderDetail(_data){  // 线下订单详情
        return request({
            url:'/system/localLifeOrder/localLifeOrderDetail?data='+ JSON.stringify(_data),
            method:'get',
        })
    },


}