import request from './request.js';

export default {

  coursecateDelete(_data) {
    //图书分类删除
    return request({
      url: '/system/course/courseCateDelete?data=' + JSON.stringify(_data),
      method: 'post',
    });
  },



  courseLableList(_data) {
    //课程标签列表
    return request({
      url: '/system/course/courseLableList?data=' + JSON.stringify(_data),
      method: 'get',
    });
  },

  addCourseLabel(_data) {
    //添加修改课程标签
    return request({
      url: '/system/course/addCourseLabel?data=' + JSON.stringify(_data),
      method: 'POST',
    });
  },

  removeLabel(_data) {
    //删除课程标签
    return request({
      url: '/system/course/removeLabel?data=' + JSON.stringify(_data),
      method: 'POST',
    });
  },
  
  updateCourseTuiJian(_data) {
    //推荐设置状态
    return request({
      url: '/system/course/updateCourseTuiJian?data=' + JSON.stringify(_data),
      method: 'POST',
    });
  },

  exportTemplate(_data) {
    //导出章节打点模板
    return request({
      url: '/system/course/exportTemplate?data=' + JSON.stringify(_data),
      method: 'get',
      responseType: 'blob'
    });
  },
  exportCourseTemplate(_data) {
    //导出章节打点模板
    return request({
      url: '/system/course/exportCourseTemplate?data=' + JSON.stringify(_data),
      method: 'get',
      responseType: 'blob'
    });
  },
  importuploadCourseFile(_data) {
    //导入题库试题课程
    return request({
      url: '/system/course/uploadCourseFile?data=' + JSON.stringify(_data),
      method: 'post',
    });
  },

  getSonCourseList(_data) {
    //课程下的子课程
    return request({
      url: '/system/course/getSonCourseList?data=' + JSON.stringify(_data),
      method: 'get',
    });
  },

  editSonCourse(_data) {
    //添加修改子课程
    return request({
      url: '/system/course/editSonCourse?data=' + JSON.stringify(_data),
      method: 'get',
    });
  },


  initCourseOrderPage(_data) {
    //课程订单列表
    return request({
      url: '/system/order/initOrderPage?data=' + JSON.stringify(_data),
      method: 'get',
    });
  },

  auditCourse(_data) {
    //审核课程
    return request({
      url: '/system/course/auditCourse?data=' + JSON.stringify(_data),
      method: 'post',
    });
  },

  courseCateDelete(_data) {
    //课程分类删除
    return request({
      url: '/system/course/courseCateDelete?data=' + JSON.stringify(_data),
      method: 'post',
    });
  },

  courseCateList(_data) {
    //课程分类列表
    return request({
      url: '/system/course/courseCateList?data=' + JSON.stringify(_data),
      method: 'get',
    });
  },

  courseCateUpdate(_data) {
    //课程分类添加 修改
    return request({
      url: '/system/course/courseCateUpdate?data=' + JSON.stringify(_data),
      method: 'post',
    });
  },

  courseDetail(_data) {
    //课程订单详情
    return request({
      url: '/system/course/courseDetail?data=' + JSON.stringify(_data),
      method: 'get',
    });
  },

  courseList(_data) {
    //课程列表
    return request({
      url: '/system/course/courseList?data=' + JSON.stringify(_data),
      method: 'get',
    });
  },

  deleteCourse(_data) {
    //删除课程
    return request({
      url: '/system/course/deleteCourse?data=' + JSON.stringify(_data),
      method: 'post',
    });
  },

  insertCourse(_data, html) {
    //添加课程
    return request({
      url: '/system/course/insertCourse?data=' + JSON.stringify(_data),
      method: 'post',
      data: html,
    });
  },

  insertChapter(_data, html) {
    //添加章节
    return request({
      url: '/system/course/insertChapter?data=' + JSON.stringify(_data),
      method: 'post',
      data: html,
    });
  },

  courseById(_data) {
    //课程详情
    return request({
      url: '/system/course/courseById?data=' + JSON.stringify(_data),
      method: 'post',
    });
  },

  shelvesCourse(_data) {
    //强制下架课程
    return request({
      url: '/system/course/shelvesCourse?data=' + JSON.stringify(_data),
      method: 'post',
    });
  },

  transcoding() {
    //七牛视频转码回调
    return request({
      url: '/system/course/transcoding-back',
      method: 'post',
    });
  },

  updateCourseTuiJian(_data) {
    //推荐设置状态
    return request({
      url: '/system/course/updateCourseTuiJian?data=' + JSON.stringify(_data),
      method: 'post',
    });
  },

  uploadToken() {
    //获取视频上传凭证
    return request({
      url: '/system/course/upload-token',
      method: 'post',
    });
  },

  courseListByTeacherId(_data) {
    //课程老师下录播课程列表
    return request({
      url: '/system/course/courseListByTeacherId?data=' + JSON.stringify(_data),
      method: 'get',
    });
  },

  teacherList(_data) {
    //课程老师列表
    return request({
      url: '/system/course/teacherList?data=' + JSON.stringify(_data),
      method: 'get',
    });
  },

  yijiancourseList(_data) {
    //一建课程列表
    return request({
      url: '/system/importCourses/yijiancourseList?data=' + JSON.stringify(_data),
      method: 'get',
    });
  },

  daorucourses(_data) {
    //导入课程
    return request({
      url: '/system/importCourses/daorucourses?data=' + JSON.stringify(_data),
      method: 'get',
    });
  },

  teacherInsert(_data) {
    //课程老师添加 修改
    return request({
      url: '/system/course/teacherInsert?data=' + JSON.stringify(_data),
      method: 'post',
    });
  },

  teacherDelete(_data) {
    //课程老师删除
    return request({
      url: '/system/course/teacherDelete?data=' + JSON.stringify(_data),
      method: 'post',
    });
  },

  courseCommentList(_data) {
    //课程评论列表
    return request({
      url: '/system/comment/courseCommentList?data=' + JSON.stringify(_data),
      method: 'get',
    });
  },
  liveList(_data) {
    //直播课程列表
    return request({
      url: '/system/course/liveList?data=' + JSON.stringify(_data),
      method: 'get',
    });
  },

  updateLive(_data) {
    //直播课程设置上下架
    return request({
      url: '/system/course/updateLive?data=' + JSON.stringify(_data),
      method: 'post',
    });
  },

  liveDetails(_data) {
    //直播课程详情
    return request({
      url: '/system/course/liveDetails?data=' + JSON.stringify(_data),
      method: 'get',
    });
  },
};
