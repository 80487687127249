import request from './request.js'

export default {
    couponLog(_data){  //优惠券记录
        return request({
            url:'/system/account/couponLog?data='+ JSON.stringify(_data),
            method:'get',
        })
    },
    
    couponDetail(_data){  //优惠券详情
        return request({
            url:'/system/coupon/couponDetail?data='+ JSON.stringify(_data),
            method:'get',
        })
    },

    createCoupon(_data){  //添加优惠券
        return request({
            url:'/system/coupon/createCoupon?data='+ JSON.stringify(_data),
            method:'post',
        })
    },

    forcedRemoval(_data){  //强制下架
        return request({
            url:'/system/coupon/forcedRemoval?data='+ JSON.stringify(_data),
            method:'post',
        })
    },

    initCouponPage(_data){  //优惠券列表
        return request({
            url:'/system/coupon/initCouponPage?data='+ JSON.stringify(_data),
            method:'get',
        })
    },

    initCouponUseInfo(_data){  //优惠券使用信息
        return request({
            url:'/system/coupon/initCouponUseInfo?data='+ JSON.stringify(_data),
            method:'get',
        })
    },

    stoppedIssuing(_data){  //停止发放
        return request({
            url:'/system/coupon/stoppedIssuing?data='+ JSON.stringify(_data),
            method:'post',
        })
    },

    restoreIssuing(_data){  //恢复发放
        return request({
            url:'/system/coupon/restore?data='+ JSON.stringify(_data),
            method:'post',
        })
    },
    removeCoupon(_data){  //删除优惠券
        return request({
            url:'/system/coupon/removeCoupon?data='+ JSON.stringify(_data),
            method:'post',
        })
    },

}