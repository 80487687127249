import request from './request.js';

import apiS from './apiS.js';
import consult from './consult.js'; //咨询、测评
import goods from './goods.js'; //商品
import course from './course.js'; //课程
import marketing from './marketing.js'; //营销（拼团、秒杀）
import coupon from './coupon.js'; //优惠券
import combo from './combo.js'; //本地生活、套餐
import integral from './integral.js'; //积分商城
import activity from './activity.js'; //活动中心
import exam from './exam.js'; //考试管理
import textimg from './textimg.js'; //图文管理

export default {
  ...apiS, //单商户
  ...consult, //咨询、测评
  ...goods, //商品
  ...course, //课程
  ...coupon, //优惠券
  ...combo, //本地生活、套餐
  ...marketing, //营销（拼团、秒杀）
  ...integral, //积分商城
  ...activity, //活动中心
  ...exam, //考试管理
  ...textimg, //图文管理

  uploadList(_data) {
    //文件上传
    return request({
      url: '/system/upload/uploadList',
      method: 'post',
      data: _data,
    });
  },

  importOrderFile22(_data) {
    //合作商导入订单
    return request({
      url: '/system/institutions/importOrderFile?data=',
      method: 'post',
      data: _data,
    });
  },

  exportUserListTemplate(_data) {
    //导出用户列表
    return request({
      url:
        '/system/import/exportUserListTemplate?data=' + JSON.stringify(_data),
      method: 'get',
      responseType: 'blob',
    });
  },

  exportInvoiceListTemplate(_data) {
    //导出发票列表
    return request({
      url:
        '/system/import/exportInvoiceListTemplate?data=' + JSON.stringify(_data),
      method: 'get',
      responseType: 'blob',
    });
  },

  exportCertificateListTemplate(_data) {
    //导出证书列表
    return request({
      url:
        '/system/import/exportCertificateListTemplate?data=' + JSON.stringify(_data),
      method: 'get',
      responseType: 'blob',
    });
  },

  exportCourseOrderListTemplate(_data) {
    //导出课程订单列表
    return request({
      url:
        '/system/import/exportCourseOrderListTemplate?data=' + JSON.stringify(_data),
      method: 'get',
      responseType: 'blob',
    });
  },

  getToken() {
    //获取上传token
    return request({
      url: '/system/upload/getToken',
      method: 'get',
    });
  },

  orderDetail(_data) {
    //课程订单详情
    return request({
      url: '/system/order/orderDetail?data=' + JSON.stringify(_data),
      method: 'get',
    });
  },

  questionDetail(_data) {
    //试题详情
    return request({
      url: '/system/exam/questionDetail?data=' + JSON.stringify(_data),
      method: 'get',
    });
  },


  feedbackList(_data) {
    //问题反馈列表
    return request({
      url: '/system/feedback/feedbackList?data=' + JSON.stringify(_data),
      method: 'get',
    });
  },
  feedbackremove(_data) {
    //删除问题反馈
    return request({
      url: '/system/feedback/remove?data=' + JSON.stringify(_data),
      method: 'post',
    });
  },
  articleDelete(_data) {
    //文章删除
    return request({
      url: '/system/article/articleDelete?data=' + JSON.stringify(_data),
      method: 'post',
    });
  },

  articleInsertOrUpdate(_data, html) {
    //文章添加或更改
    return request({
      url:
        '/system/article/articleInsertOrUpdate?data=' + JSON.stringify(_data),
      method: 'post',
      data: html,
    });
  },

  articleList(_data) {
    //文章列表
    return request({
      url: '/system/article/articleList?data=' + JSON.stringify(_data),
      method: 'get',
    });
  },

  zixunList(_data) {
    //文章列表
    return request({
      url: '/system/news/newsList?data=' + JSON.stringify(_data),
      method: 'get',
    });
  },

  zixuninsertNews(_data, html) {
    //添加-修改 资讯
    return request({
      url: '/system/news/insertNews?data=' + JSON.stringify(_data),
      method: 'post',
      data: html,
    });
  },
  zixundeleteNews(_data) {
    //文章删除
    return request({
      url: '/system/news/deleteNews?data=' + JSON.stringify(_data),
      method: 'post',
    });
  },

  workbenchesinfo(_data) {
    //基本数据
    return request({
      url: '/system/workbenches/info?data=' + JSON.stringify(_data),
      method: 'get',
    });
  },

  lineChart(_data) {
    //基本数据
    return request({
      url: '/system/workbenches/lineChart?data=' + JSON.stringify(_data),
      method: 'get',
    });
  },
  orderLineChart(_data) {
    //订单折线图
    return request({
      url: '/system/workbenches/orderLineChart?data=' + JSON.stringify(_data),
      method: 'get',
    });
  },
  pieChartDto(_data) {
    //订单饼状图
    return request({
      url: '/system/workbenches/pieChartDto?data=' + JSON.stringify(_data),
      method: 'get',
    });
  },
  getWorkbenchesDto(_data) {
    //最多图书和最多课程
    return request({
      url: '/system/workbenches/getWorkbenchesDto?data=' + JSON.stringify(_data),
      method: 'get',
    });
  },

  backgroundList(_data) {
    //背景页列表接口
    return request({
      url: '/system/banner/backgroundList?data=' + JSON.stringify(_data),
      method: 'get',
    });
  },
  aboutUs() {
    //关于我们
    return request({
      url: '/system/aboutUs/aboutUs',
      method: 'get',
    });
  },
  initInvoiceList(_data) {
    //初始化发票列表
    return request({
      url: '/system/invoice/initInvoiceList?data=' + JSON.stringify(_data),
      method: 'get',
    });
  },
  openInvoice(_data) {
    //初始化发票列表
    return request({
      url: '/system/invoice/openInvoice?data=' + JSON.stringify(_data),
      method: 'post',
    });
  },

  partnersList(_data) {
    //战略合作列表
    return request({
      url: '/system/partners/list?data=' + JSON.stringify(_data),
      method: 'get',
    });
  },
  partnersedit(_data) {
    //添加修改战略合作
    return request({
      url: '/system/partners/edit?data=' + JSON.stringify(_data),
      method: 'post',
    });
  },
  partnersremove(_data) {
    //删除战略合作
    return request({
      url: '/system/partners/remove?data=' + JSON.stringify(_data),
      method: 'post',
    });
  },
  updateAboutUs(_data) {
    //修改关于我们
    return request({
      url: '/system/aboutUs/updateAboutUs?data=' + JSON.stringify(_data),
      method: 'post',
    });
  },

  authPasswordLogin(_data) {
    //管理员登录接口
    return request({
      url: '/system/login/authPasswordLogin?data=' + JSON.stringify(_data),
      method: 'post',
    });
  },

  auditDetail(_data) {
    //商户入驻审核详情
    return request({
      url: '/system/shopAudit/auditDetail?data=' + JSON.stringify(_data),
      method: 'get',
    });
  },

  initFreightTemplates(_data) {
    //初始化运费模板
    return request({
      url: '/system/freight/initFreightTemplates?data=' + JSON.stringify(_data),
      method: 'get',
    });
  },

  createFreightTemplate(_data) {
    //添加运费模板
    return request({
      url:
        '/system/freight/createFreightTemplate?data=' + JSON.stringify(_data),
      method: 'post',
    });
  },

  editTemplateInfo(_data) {
    //（添加-删除-编辑）模板信息
    return request({
      url: '/system/freight/editTemplateInfo?data=' + JSON.stringify(_data),
      method: 'post',
    });
  },

  freightTemplateDetail(_data) {
    //运费模板详情
    return request({
      url:
        '/system/freight/freightTemplateDetail?data=' + JSON.stringify(_data),
      method: 'get',
    });
  },

  removeFreightTemplate(_data) {
    //删除运费模板
    return request({
      url:
        '/system/freight/removeFreightTemplate?data=' + JSON.stringify(_data),
      method: 'post',
    });
  },

  setDefaultTemplate(_data) {
    //设置默认模板
    return request({
      url: '/system/freight/setDefaultTemplate?data=' + JSON.stringify(_data),
      method: 'post',
    });
  },

  auditRemove(_data) {
    //审核删除
    return request({
      url: '/system/shopAudit/delete?data=' + JSON.stringify(_data),
      method: 'post',
    });
  },

  auditRefuse(_data) {
    //审核拒绝
    return request({
      url: '/system/shopAudit/auditRefuse?data=' + JSON.stringify(_data),
      method: 'post',
    });
  },

  shopAuditList(_data) {
    //初始化入驻审核页
    return request({
      url: '/system/shopAudit/shopAuditList?data=' + JSON.stringify(_data),
      method: 'post',
    });
  },

  submitSetShareBenefit(_data) {
    //提交设置分润比例
    return request({
      url:
        '/system/shopAudit/submitSetShareBenefit?data=' + JSON.stringify(_data),
      method: 'post',
    });
  },

  getShopBaseInfo(_data) {
    //商户详情
    return request({
      url: '/system/shop/getShopBaseInfo?data=' + JSON.stringify(_data),
      method: 'get',
    });
  },

  freezeShopAccount(_data) {
    //冻结商户账号
    return request({
      url: '/system/shop/freezeShopAccount?data=' + JSON.stringify(_data),
      method: 'post',
    });
  },

  initShopListPage(_data) {
    //商户管理列表
    return request({
      url: '/system/shop/initShopListPage?data=' + JSON.stringify(_data),
      method: 'post',
    });
  },

  resetPasswordForShop(_data) {
    //重置密码
    return request({
      url: '/system/shop/resetPasswordForShop?data=' + JSON.stringify(_data),
      method: 'post',
    });
  },

  initAccountPage(_data) {
    //用户列表初始化
    return request({
      url: '/system/account/initAccountPage?data=' + JSON.stringify(_data),
      method: 'get',
    });
  },
  userCourseList(_data) {
    //用户购买的课程列表
    return request({
      url: '/system/account/userCourseList?data=' + JSON.stringify(_data),
      method: 'get',
    });
  },
  userQuestionsCompositonList(_data) {
    //用户赠送的试卷列表
    return request({
      url: '/system/account/userQuestionsCompositonList?data=' + JSON.stringify(_data),
      method: 'get',
    });
  },


  consumerList(_data) {
    //消费账单
    return request({
      url: '/system/account/consumerList?data=' + JSON.stringify(_data),
      method: 'get',
    });
  },

  recommendList(_data) {
    //推荐人数
    return request({
      url: '/system/account/recommendList?data=' + JSON.stringify(_data),
      method: 'get',
    });
  },

  followShop(_data) {
    //关注店铺列表
    return request({
      url: '/system/account/followShop?data=' + JSON.stringify(_data),
      method: 'get',
    });
  },

  freezeAndRecovery(_data) {
    //冻结|恢复
    return request({
      url: '/system/account/freezeAndRecovery?data=' + JSON.stringify(_data),
      method: 'get',
    });
  },

  getUserBaseInfoForShop(_data) {
    //用户详情
    return request({
      url:
        '/system/account/getUserBaseInfoForShop?data=' + JSON.stringify(_data),
      method: 'get',
    });
  },

  advertiseList(_data) {
    //广告列表接口
    return request({
      url: '/system/banner/advertiseList?data=' + JSON.stringify(_data),
      method: 'get',
    });
  },

  advertiseUpdate(_data) {
    //广告修改接口
    return request({
      url: '/system/banner/advertiseUpdate?data=' + JSON.stringify(_data),
      method: 'get',
    });
  },

  bannerList(_data) {
    //轮播图列表接口
    return request({
      url: '/system/banner/bannerList?data=' + JSON.stringify(_data),
      method: 'get',
    });
  },

  bannerUpdate(_data) {
    //轮播图修改接口
    return request({
      url: '/system/banner/bannerUpdate?data=' + JSON.stringify(_data),
      method: 'get',
    });
  },

  bannerDelete(_data) {
    //轮播图删除接口
    return request({
      url: '/system/banner/bannerDelete?data=' + JSON.stringify(_data),
      method: 'get',
    });
  },

  applyList(_data) {
    //合伙人申请列表
    return request({
      url: '/system/partner/applyList?data=' + JSON.stringify(_data),
      method: 'get',
    });
  },

  partnerUserList(_data) {
    //区域下的合伙人列表
    return request({
      url: '/system/partner/partnerUserList?data=' + JSON.stringify(_data),
      method: 'get',
    });
  },

  insertPartner(_data) {
    //添加区域
    return request({
      url: '/system/partner/insertPartner?data=' + JSON.stringify(_data),
      method: 'get',
    });
  },

  insertUser(_data) {
    //添加代理人
    return request({
      url: '/system/partner/insertUser?data=' + JSON.stringify(_data),
      method: 'get',
    });
  },

  partnerDetail(_data) {
    //代理商明细
    return request({
      url: '/system/partner/partnerDetail?data=' + JSON.stringify(_data),
      method: 'get',
    });
  },

  updateSystemSetup(_data) {
    //修改分佣比例
    return request({
      url: '/system/partner/updateSystemSetup?data=' + JSON.stringify(_data),
      method: 'post',
    });
  },

  exportUserWithdrawListTemplate(_data) {
    //导出用户提现列表
    return request({
      url:
        '/api/finance/exportUserWithdrawListTemplate?data=' +
        JSON.stringify(_data),
      method: 'get',
      responseType: 'blob',
    });
  },

  exportFinanceListTemplate(_data) {
    //导出财务列表
    return request({
      url:
        '/api/finance/exportFinanceListTemplate?data=' + JSON.stringify(_data),
      method: 'get',
      responseType: 'blob',
    });
  },

  userWithdrawUpdate(_data) {
    //用户提现操作
    return request({
      url: '/api/finance/userWithdrawUpdate?data=' + JSON.stringify(_data),
      method: 'get',
    });
  },

  userWithdrawList(_data) {
    //用户提现列表
    return request({
      url: '/api/finance/userWithdrawList?data=' + JSON.stringify(_data),
      method: 'get',
    });
  },

  financeList(_data) {
    //财务列表
    return request({
      url: '/api/finance/financeList?data=' + JSON.stringify(_data),
      method: 'get',
    });
  },

  initAdminPage(_data) {
    //管理员列表初始化
    return request({
      url: '/api/gkAdminCenter/adminList?data=' + JSON.stringify(_data),
      method: 'get',
    });
  },

  adminDetail(_data) {
    //管理员详情
    return request({
      url: '/api/gkAdminCenter/adminInfo?data=' + JSON.stringify(_data),
      method: 'get',
    });
  },

  createAdmin(_data) {
    //添加管理员
    return request({
      url: '/api/gkAdminCenter/insert?data=' + JSON.stringify(_data),
      method: 'post',
    });
  },

  editAdmin(_data) {
    //编辑管理员
    return request({
      url: '/api/gkAdminCenter/update?data=' + JSON.stringify(_data),
      method: 'post',
    });
  },

  removeAdmin(_data) {
    //删除管理员
    return request({
      url: '/api/gkAdminCenter/delete?data=' + JSON.stringify(_data),
      method: 'post',
    });
  },

  forgetPasswordAuthIdentity(_data) {
    //忘记密码-身份验证
    return request({
      url:
        '/system/login/forgetPasswordAuthIdentity?data=' +
        JSON.stringify(_data),
      method: 'get',
    });
  },

  forgetPasswordSubmitChange(_data) {
    //忘记密码-修改密码
    return request({
      url:
        '/system/login/forgetPasswordSubmitChange?data=' +
        JSON.stringify(_data),
      method: 'get',
    });
  },

  roleList(_data) {
    //身份列表初始化
    return request({
      url: '/api/gkAdminRole/roleList?data=' + JSON.stringify(_data),
      method: 'get',
    });
  },

  getAllRoles() {
    //所有身份列表
    return request({
      url: '/api/gkAdminRole/getAllRoles',
      method: 'get',
    });
  },

  createRole(_data) {
    //添加角色
    return request({
      url: '/api/gkAdminRole/createRole?data=' + JSON.stringify(_data),
      method: 'post',
    });
  },

  editRole(_data) {
    //编辑角色
    return request({
      url: '/api/gkAdminRole/editRole?data=' + JSON.stringify(_data),
      method: 'post',
    });
  },

  removeRole(_data) {
    //删除角色
    return request({
      url: '/api/gkAdminRole/removeRole?data=' + JSON.stringify(_data),
      method: 'post',
    });
  },

  getBindingPermission(_data) {
    //获取角色已绑定权限
    return request({
      url:
        '/api/gkAdminRole/getBindingPermission?data=' + JSON.stringify(_data),
      method: 'get',
    });
  },

  distributionPermissionForRole(_data) {
    //分配角色权限
    return request({
      url:
        '/api/gkAdminRole/distributionPermissionForRole?data=' +
        JSON.stringify(_data),
      method: 'post',
    });
  },

  getMenuTree() {
    //获取所有菜单树
    return request({
      url: '/api/permission/getMenuTree',
      method: 'get',
    });
  },

  getAdminWithTheMenuTree() {
    //获取用户拥有的菜单树
    return request({
      url: '/api/permission/getAdminWithTheMenuTree',
      method: 'get',
    });
  },

  initOperationLogPage(_data) {
    //操作日志列表
    return request({
      url:
        '/system/operation/initOperationLogPage?data=' + JSON.stringify(_data),
      method: 'get',
    });
  },

  initShopShareBenefit(_data) {
    //商户分润比例设置初始化
    return request({
      url: '/system/audit/initShopShareBenefit?data=' + JSON.stringify(_data),
      method: 'get',
    });
  },

  recoveryShopAccount(_data) {
    //恢复商户账号
    return request({
      url: '/system/shop/recoveryShopAccount?data=' + JSON.stringify(_data),
      method: 'get',
    });
  },

  getShopGoodsPage(_data) {
    //商品列表
    return request({
      url: '/system/shop/getShopGoodsPage?data=' + JSON.stringify(_data),
      method: 'get',
    });
  },

  initShopLocalLifeOrderPage(_data) {
    //店铺线下订单列表
    return request({
      url:
        '/system/shop/initShopLocalLifeOrderPage?data=' + JSON.stringify(_data),
      method: 'get',
    });
  },

  localLifeShopOrderDetail(_data) {
    //店铺线下订单详情
    return request({
      url:
        '/system/shop/localLifeShopOrderDetail?data=' + JSON.stringify(_data),
      method: 'get',
    });
  },

  getShopWithdrawalAuditPage(_data) {
    //店铺提现审核列表初始化
    return request({
      url:
        '/system/shop/getShopWithdrawalAuditPage?data=' + JSON.stringify(_data),
      method: 'get',
    });
  },

  getShopGoodsOrderPage(_data) {
    //商品订单
    return request({
      url: '/system/shop/getShopGoodsOrderPage?data=' + JSON.stringify(_data),
      method: 'get',
    });
  },

  getShopWithdrawalLPage(_data) {
    //店铺提现列表初始化
    return request({
      url: '/system/shop/getShopWithdrawalLPage?data=' + JSON.stringify(_data),
      method: 'get',
    });
  },

  getShopBalanceLogPage(_data) {
    //店铺余额列表初始化
    return request({
      url: '/system/shop/getShopBalanceLogPage?data=' + JSON.stringify(_data),
      method: 'get',
    });
  },

  getUserGoodsOrderPage(_data) {
    //商品订单
    return request({
      url:
        '/system/account/getUserGoodsOrderPage?data=' + JSON.stringify(_data),
      method: 'get',
    });
  },

  getPlatformCategories() {
    //平台商品分类列表
    return request({
      url: '/system/goodsCategory/getPlatformCategories',
      method: 'get',
    });
  },

  addeditlocalLifeCategory(_data) {
    //添加编辑分类
    return request({
      url:
        '/system/localLifeCategory/addeditlocalLifeCategory?data=' +
        JSON.stringify(_data),
      method: 'post',
    });
  },

  deletelocalLifeCategory(_data) {
    //删除线下店铺分类
    return request({
      url:
        '/system/localLifeCategory/deletelocalLifeCategory?data=' +
        JSON.stringify(_data),
      method: 'get',
    });
  },

  localLifeCategoryList() {
    //线下店铺分类列表
    return request({
      url: '/system/localLifeCategory/localLifeCategoryList',
      method: 'get',
    });
  },

  addLocalLifeBanner(_data) {
    //添加线下banner
    return request({
      url: '/system/banner/addLocalLifeBanner?data=' + JSON.stringify(_data),
      method: 'post',
    });
  },

  getLocalLifeBannerList(_data) {
    //获取线下banner列表
    return request({
      url:
        '/system/banner/getLocalLifeBannerList?data=' + JSON.stringify(_data),
      method: 'get',
    });
  },

  updateLocalLifeBanner(_data) {
    //修改线下banner
    return request({
      url: '/system/banner/updateLocalLifeBanner?data=' + JSON.stringify(_data),
      method: 'post',
    });
  },

  deleteLocalLifeBanner(_data) {
    //删除线下banner
    return request({
      url: '/system/banner/deleteLocalLifeBanner?data=' + JSON.stringify(_data),
      method: 'post',
    });
  },

  // 系统消息 start-----------------------------------------------------------------

  findMessageList(_data) {
    // 系统消息列表
    return request({
      url: '/api/gkMssage/messageList?data=' + JSON.stringify(_data),
      method: 'get',
    });
  },

  choiceUserList(_data) {
    //发送消息时 选择用户列表
    return request({
      url: '/api/gkMssage/userListDto?data=' + JSON.stringify(_data),
      method: 'get',
    });
  },

  consultationTeacherList(_data) {
    // 选择咨询师列表
    return request({
      url:
        '/system/consultation/consultationTeacherList?data=' +
        JSON.stringify(_data),
      method: 'get',
    });
  },

  addMessage(_data, html) {
    // 发布消息
    return request({
      url: '/api/gkMssage/insert?data=' + JSON.stringify(_data),
      method: 'post',
      data: {
        content: html,
      },
    });
  },

  deleteMessage(_data) {
    // 删除系统消息
    return request({
      url: '/api/gkMssage/delete?data=' + JSON.stringify(_data),
      method: 'post',
    });
  },

  // 系统消息 end-----------------------------------------------------------------

  initProvinceList(_data) {
    // 省列表初始化
    return request({
      url: '/system/region/initProvinceList?data=' + JSON.stringify(_data),
      method: 'get',
    });
  },

  addeditRegion(_data) {
    // 添加编辑省市区商圈
    return request({
      url: '/system/region/addeditRegion?data=' + JSON.stringify(_data),
      method: 'post',
    });
  },

  removeRegion(_data) {
    // 删除省市区商圈
    return request({
      url: '/system/region/removeRegion?data=' + JSON.stringify(_data),
      method: 'post',
    });
  },

  // app版本管理 start-------------------------------------------------------------------------------

  userCreate(_data) {
    // 新增用户端版本
    return request({
      url: '/system/app-version/create?data=' + JSON.stringify(_data),
      method: 'post',
    });
  },

  userGetNow() {
    // 获取用户端当前最新版本
    return request({
      url: '/system/app-version/getNow',
      method: 'get',
    });
  },

  userPage(_data) {
    // 获取用户端版本列表
    return request({
      url: '/system/app-version/page?data=' + JSON.stringify(_data),
      method: 'get',
    });
  },

  shopCreate(_data) {
    // 新增商铺端版本
    return request({
      url: '/system/app-version/shop-create?data=' + JSON.stringify(_data),
      method: 'get',
    });
  },

  shopGetNow() {
    // 获取商铺端当前最新版本
    return request({
      url: '/system/app-version/shop-getNow',
      method: 'get',
    });
  },

  shopPage(_data) {
    // 获取商铺端版本列表
    return request({
      url: '/system/app-version/shop-page?data=' + JSON.stringify(_data),
      method: 'get',
    });
  },

  shopSync(_data) {
    // 编辑商铺端版本
    return request({
      url: '/system/app-version/shop-sync?data=' + JSON.stringify(_data),
      method: 'get',
    });
  },

  userSync(_data) {
    // 编辑用户端版本
    return request({
      url: '/system/app-version/sync?data=' + JSON.stringify(_data),
      method: 'get',
    });
  },

  // app版本管理 end---------------------------------------------------------------------------------

  createKnowXiangMai(_data) {
    // 新增知道响买
    return request({
      url: '/system/the-text/createKnowXiangMai?data=' + JSON.stringify(_data),
      method: 'post',
    });
  },

  knowXiangMaipage(_data) {
    // 知道响买分页列表
    return request({
      url: '/system/the-text/knowXiangMaipage?data=' + JSON.stringify(_data),
      method: 'get',
    });
  },

  updateKnowXiangMai(_data) {
    // 编辑知道响买
    return request({
      url: '/system/the-text/updateKnowXiangMai?data=' + JSON.stringify(_data),
      method: 'post',
    });
  },

  deleteKnowXiangMai(_data) {
    // 删除知道响买{id}
    return request({
      url: '/system/the-text/deleteKnowXiangMai/' + _data,
      method: 'get',
    });
  },

  insertBook(_data, html) {
    //添加图书
    return request({
      url:
        '/system/book/insertBook?data=' + JSON.stringify(_data),
      method: 'post',
      data: html,
    });
  },

  bookCateList(_data) {
    //图书分类列表
    return request({
      url: '/system/book/bookCateList?data=' + JSON.stringify(_data),
      method: 'get',
    });
  },

  tushuCateDelete(_data) {
    //图书分类删除
    return request({
      url: '/system/book/courseCateDelete?data=' + JSON.stringify(_data),
      method: 'post',
    });
  },

  tushucourseCateUpdate(_data) {
    //图书分类删除
    return request({
      url: '/system/book/courseCateUpdate?data=' + JSON.stringify(_data),
      method: 'post',
    });
  },

  tushucourseList(_data) {
    //图书分类列表
    return request({
      url: '/system/book/courseList?data=' + JSON.stringify(_data),
      method: 'get',
    });
  },

  tushugetSonCourseList(_data) {
    //图书下的规格列表
    return request({
      url: '/system/book/getSonCourseList?data=' + JSON.stringify(_data),
      method: 'get',
    });
  },

  tushueditSonCourse(_data) {
    //添加修改图书规格
    return request({
      url: '/system/book/editSonCourse?data=' + JSON.stringify(_data),
      method: 'get',
    });
  },
  userresettingPassword(_data) {
    //重置密码
    return request({
      url: '/system/account/resettingPassword?data=' + JSON.stringify(_data),
      method: 'post',
    });
  },

  pcAboutUs(_data) {
    //pc关于我们
    return request({
      url: '/system/aboutUs/pcAboutUs?data=' + JSON.stringify(_data),
      method: 'get',
    });
  },

  pcupdateAboutUs(_data) {
    //修改关于我们
    return request({
      url: '/system/aboutUs/updateAboutUs?data=' + JSON.stringify(_data),
      method: 'post',
    });
  },
  pcContactsUs(_data) {
    //pc联系我们
    return request({
      url: '/system/aboutUs/pcContactsUs?data=' + JSON.stringify(_data),
      method: 'get',
    });
  },

  getUserCompositionList(_data) {
    //试题下用户考试记录
    return request({
      url: '/system/exam/getUserCompositionList?data=' + JSON.stringify(_data),
      method: 'get',
    });
  },





  zixundetail(_data) {
    //资讯详情
    return request({
      url: '/system/news/detail?data=' + JSON.stringify(_data),
      method: 'get',
    });
  },
  articleCateList(_data) {
    //帮助中心分类列表
    return request({
      url: '/system/article/articleCateList?data=' + JSON.stringify(_data),
      method: 'get',
    });
  },

  deleteArticleCate(_data) {
    //删除帮助中心分类
    return request({
      url: '/system/article/deleteArticleCate?data=' + JSON.stringify(_data),
      method: 'post',
    });
  },

  editArticleCate(_data) {
    //添加修改帮助中心分类
    return request({
      url: '/system/article/editArticleCate?data=' + JSON.stringify(_data),
      method: 'post',
    });
  },

  articleDetail(_data) {
    //文章详情
    return request({
      url: '/system/article/articleDetail?data=' + JSON.stringify(_data),
      method: 'get',
    });
  },




  ddtemplateList(_data) {
    //证书列表
    return request({
      url: '/system/template/templateList?data=' + JSON.stringify(_data),
      method: 'get',
    });
  },

  zhengshuDelete(_data) {
    //图书分类删除
    return request({
      url: '/system/template/remove?data=' + JSON.stringify(_data),
      method: 'post',
    });
  },

  zhengshueditAdd(_data) {
    //图书分类删除
    return request({
      url: '/system/template/editAdd?data=' + JSON.stringify(_data),
      method: 'post',
    });
  },
  subCertificate(_data) {
    //图书分类删除
    return request({
      url: '/system/template/subCertificate?data=' + JSON.stringify(_data),
      method: 'post',
    });
  },
  getCertificateUserList(_data) {
    //证书列表
    return request({
      url: '/system/template/getCertificateUserList?data=' + JSON.stringify(_data),
      method: 'get',
    });
  },

  learningMaterialsCategoryList(_data) {
    //学习资料分类列表
    return request({
      url: '/system/learningMaterials/learningMaterialsCategoryList?data=' + JSON.stringify(_data),
      method: 'get',
    });
  },
  editLearningMaterialsCategory(_data) {
    //添加修改学习资料分类
    return request({
      url: '/system/learningMaterials/editLearningMaterialsCategory?data=' + JSON.stringify(_data),
      method: 'post',
    });
  },
  removeLearningMaterialsCategory(_data) {
    //删除学习资料分类
    return request({
      url: '/system/learningMaterials/removeLearningMaterialsCategory?data=' + JSON.stringify(_data),
      method: 'post',
    });
  },

  learningMaterialsList(_data) {
    //学习资料列表
    return request({
      url: '/system/learningMaterials/learningMaterialsList?data=' + JSON.stringify(_data),
      method: 'get',
    });
  },
  editLearningMaterials(_data) {
    //添加修改学习资料
    return request({
      url: '/system/learningMaterials/editLearningMaterials?data=' + JSON.stringify(_data),
      method: 'post',
    });
  },
  removeLearningMaterials(_data) {
    //删除学习资料
    return request({
      url: '/system/learningMaterials/removeLearningMaterials?data=' + JSON.stringify(_data),
      method: 'post',
    });
  },
  tuijianLearningMaterials(_data) {
    //删除学习资料
    return request({
      url: '/system/learningMaterials/tuijian?data=' + JSON.stringify(_data),
      method: 'post',
    });
  },
  institutionsList(_data) {
    //招生合作单位列表
    return request({
      url: '/system/institutions/institutionsList?data=' + JSON.stringify(_data),
      method: 'get',
    });
  },

  institutionsCoursePage(_data) {
    //招生合作单位列表
    return request({
      url: '/system/institutions/institutionsCoursePage?data=' + JSON.stringify(_data),
      method: 'get',
    });
  },

  institutionsSonCourseOrderPage(_data) {
    //招生合作单位列表
    return request({
      url: '/system/institutions/institutionsSonCourseOrderPage?data=' + JSON.stringify(_data),
      method: 'get',
    });
  },

  courseCourseSonCourseListDto(_data) {
    //添加修改学习资料
    return request({
      url: '/system/course/courseSonCourseListDto?data=' + JSON.stringify(_data),
      method: 'get',
    });
  },

  orderForShipment(_data) {
    //添加修改学习资料
    return request({
      url: '/system/order/orderForShipment?data=' + JSON.stringify(_data),
      method: 'post',
    });
  },
  viewLogisticsInfoss(_data) {
    //添加修改学习资料
    return request({
      url: '/system/order/viewLogisticsInfo?data=' + JSON.stringify(_data),
      method: 'get',
    });
  },






  editInstitutions(_data) {
    //添加修改学习资料
    return request({
      url: '/system/institutions/editInstitutions?data=' + JSON.stringify(_data),
      method: 'post',
    });
  },
  removeInstitutions(_data) {
    //删除经销商
    return request({
      url: '/system/institutions/removeInstitutions?data=' + JSON.stringify(_data),
      method: 'post',
    });
  },

  initInstitutionsAccountPage(_data) {
    //经销商下的用户列表
    return request({
      url: '/system/institutions/initInstitutionsAccountPage?data=' + JSON.stringify(_data),
      method: 'get',
    });
  },
  updateCourseTuiJian1(_data) {
    //推荐设置状态
    return request({
      url: '/system/book/updateCourseTuiJian?data=' + JSON.stringify(_data),
      method: 'post',
    });
  },

  bookupdateCourseTuiJian(_data) {
    //推荐设置状态
    return request({
      url: '/system/book/updateCourseTuiJian?data=' + JSON.stringify(_data),
      method: 'post',
    });
  },

  exportOrderTemplates(_data) {
    //导出订单导入模板
    return request({
      url:
        '/system/institutions/exportOrderTemplate?data=' +
        JSON.stringify(_data),
      method: 'get',
      responseType: 'blob',
    });
  },

  institutionsOrderPage(_data) {
    //合作单位下的订单列表
    return request({
      url: '/system/institutions/institutionsOrderPage?data=' + JSON.stringify(_data),
      method: 'get',
    });
  },

};
