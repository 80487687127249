import request from './request.js';

export default {
  consultationTeacherList(_data) {
    //咨询师列表
    return request({
      url:
        '/system/consultation/consultationTeacherList?data=' +
        JSON.stringify(_data),
      method: 'get',
    });
  },

  consultationTeacherListTemplate(_data) {
    //导出咨询师列表
    return request({
      url:
        '/system/consultation/consultationTeacherListTemplate?data=' +
        JSON.stringify(_data),
      method: 'get',
      responseType: 'blob',
    });
  },

  insertOrUpdateConsultationTeacher(_data, html) {
    //添加、修改 咨询师
    return request({
      url:
        '/system/consultation/insertOrUpdateConsultationTeacher?data=' +
        JSON.stringify(_data),
      method: 'post',
      data: html,
    });
  },

  consultationTeacherLabelList() {
    //咨询师领域列表
    return request({
      url: '/system/consultation/consultationTeacherLabelList',
      method: 'get',
    });
  },

  consultationTeacherLevelList() {
    //咨询师级别列表
    return request({
      url: '/system/consultation/consultationTeacherLevelList',
      method: 'get',
    });
  },

  consultationDepartmentList(_data) {
    //咨询师科室列表
    return request({
      url:
        '/system/consultation/consultationDepartmentList?data=' +
        JSON.stringify(_data),
      method: 'get',
    });
  },

  consultationOrderList(_data) {
    //咨询师订单列表
    return request({
      url:
        '/system/consultation/consultationOrderList?data=' +
        JSON.stringify(_data),
      method: 'get',
    });
  },

  consultationTeacherById(_data) {
    //咨询师详情
    return request({
      url:
        '/system/consultation/consultationTeacherById?data=' +
        JSON.stringify(_data),
      method: 'get',
    });
  },

  consultationOrderDetails(_data) {
    //咨询师订单详情
    return request({
      url:
        '/system/consultation/consultationOrderDetails?data=' +
        JSON.stringify(_data),
      method: 'get',
    });
  },

  consultationTeacherBillLogList(_data) {
    //咨询师账单列表
    return request({
      url:
        '/system/consultation/consultationTeacherBillLogList?data=' +
        JSON.stringify(_data),
      method: 'get',
    });
  },

  userEvaluationList(_data) {
    //用户测评记录
    return request({
      url:
        '/system/consultation/userEvaluationList?data=' + JSON.stringify(_data),
      method: 'get',
    });
  },

  consultWithdrawList(_data) {
    //咨询师提现列表
    return request({
      url:
        '/system/consultation/userWithdrawList?data=' + JSON.stringify(_data),
      method: 'get',
    });
  },

  consultWithdrawUpdate(_data) {
    //咨询师提现操作
    return request({
      url:
        '/system/consultation/userWithdrawUpdate?data=' + JSON.stringify(_data),
      method: 'get',
    });
  },

  exportConsultWithdrawListTemplate(_data) {
    //导出咨询师提现列表
    return request({
      url:
        '/system/consultation/exportUserWithdrawListTemplate?data=' +
        JSON.stringify(_data),
      method: 'get',
      responseType: 'blob',
    });
  },

  evaluationUserListDetails(_data) {
    //测评详情
    return request({
      url:
        '/system/consultation/evaluationUserListDetails?data=' +
        JSON.stringify(_data),
      method: 'get',
    });
  },

  evaluationUserList(_data) {
    //测评用户列表
    return request({
      url: '/system/appraisal/evaluationUserList?data=' + JSON.stringify(_data),
      method: 'get',
    });
  },

  // 测评 start ====================================================================================================================================

  gaugeOrderList(_data) {
    //测评订单列表
    return request({
      url: '/system/appraisal/gaugeOrderList?data=' + JSON.stringify(_data),
      method: 'get',
    });
  },

  gaugeOrderDetails(_data) {
    //测评订单详情
    return request({
      url: '/system/appraisal/gaugeOrderDetails?data=' + JSON.stringify(_data),
      method: 'get',
    });
  },

  growUpList(_data) {
    //成长值列表
    return request({
      url: '/system/appraisal/growUpList?data=' + JSON.stringify(_data),
      method: 'get',
    });
  },

  evaluationReportList(_data) {
    //量表总结果列表
    return request({
      url:
        '/system/appraisal/evaluationReportList?data=' + JSON.stringify(_data),
      method: 'get',
    });
  },

  dimensionScore(_data) {
    //维度分值
    return request({
      url: '/system/appraisal/dimensionScore?data=' + JSON.stringify(_data),
      method: 'get',
    });
  },

  evaluationList(_data) {
    //测评列表
    return request({
      url: '/system/appraisal/evaluationList?data=' + JSON.stringify(_data),
      method: 'get',
    });
  },

  evaluationDetails(_data) {
    //测评详情
    return request({
      url: '/system/appraisal/evaluationDetails?data=' + JSON.stringify(_data),
      method: 'get',
    });
  },

  evaluationDimensionList(_data) {
    //测评下的维度列表
    return request({
      url:
        '/system/appraisal/evaluationDimensionList?data=' +
        JSON.stringify(_data),
      method: 'get',
    });
  },

  evaluationQuestionList(_data) {
    //测评下的试题列表
    return request({
      url:
        '/system/appraisal/evaluationQuestionList?data=' +
        JSON.stringify(_data),
      method: 'get',
    });
  },

  getGaugeQuestionByInsert(_data) {
    //添加题库下的试题列表
    return request({
      url:
        '/system/appraisal/getGaugeQuestionByInsert?data=' +
        JSON.stringify(_data),
      method: 'get',
    });
  },

  insertEvaluationReport(_data) {
    //添加或修改量表总结果
    return request({
      url:
        '/system/appraisal/insertEvaluationReport?data=' +
        JSON.stringify(_data),
      method: 'post',
    });
  },

  deleteEvaluation(_data) {
    //删除量表总结果
    return request({
      url: '/system/appraisal/deleteEvaluation?data=' + JSON.stringify(_data),
      method: 'post',
    });
  },

  deleteEvaluationReport(_data) {
    //删除量表总结果
    return request({
      url:
        '/system/appraisal/deleteEvaluationReport?data=' +
        JSON.stringify(_data),
      method: 'post',
    });
  },

  deleteEvaluationQuestion(_data) {
    //删除测评下的试题
    return request({
      url:
        '/system/appraisal/deleteEvaluationQuestion?data=' +
        JSON.stringify(_data),
      method: 'post',
    });
  },

  insertEvaluationDimension(_data) {
    //添加维度
    return request({
      url:
        '/system/appraisal/insertEvaluationDimension?data=' +
        JSON.stringify(_data),
      method: 'post',
    });
  },

  insertQuestionByDimension(_data) {
    //维度下添加试题
    return request({
      url:
        '/system/appraisal/insertQuestionByDimension?data=' +
        JSON.stringify(_data),
      method: 'post',
    });
  },

  insertEvaluation(_data, html) {
    //添加或修改测评
    return request({
      url: '/system/appraisal/insertEvaluation?data=' + JSON.stringify(_data),
      method: 'post',
      data: html,
    });
  },

  // -------------------------------------------------------------------------------------

  evaluationCateUpdate(_data) {
    //测评分类添加 修改
    return request({
      url:
        '/system/appraisal/evaluationCateUpdate?data=' + JSON.stringify(_data),
      method: 'post',
    });
  },

  evaluationCateList(_data) {
    //测评分类列表
    return request({
      url: '/system/appraisal/evaluationCateList?data=' + JSON.stringify(_data),
      method: 'get',
    });
  },

  evaluationCateDelete(_data) {
    //测评分类删除
    return request({
      url:
        '/system/appraisal/evaluationCateDelete?data=' + JSON.stringify(_data),
      method: 'post',
    });
  },

  deleteQuestion1(_data) {
    //删除试题
    return request({
      url: '/system/appraisal/deleteQuestion?data=' + JSON.stringify(_data),
      method: 'post',
    });
  },

  evaluationQuestionDetail(_data) {
    //试题详情
    return request({
      url:
        '/system/appraisal/evaluationQuestionDetail?data=' +
        JSON.stringify(_data),
      method: 'get',
    });
  },

  exportTemplate() {
    //导入试题模板
    return request({
      url: '/system/appraisal/exportTemplate',
      method: 'get',
      responseType: 'blob',
    });
  },

  gaugeQuestionList(_data) {
    //量表题库列表
    return request({
      url: '/system/appraisal/gaugeQuestionList?data=' + JSON.stringify(_data),
      method: 'get',
    });
  },

  getGaugeQuestion(_data) {
    //题库下的试题列表
    return request({
      url: '/system/appraisal/getGaugeQuestion?data=' + JSON.stringify(_data),
      method: 'get',
    });
  },

  importEvaluation(_data) {
    //导入题库试题课程
    return request({
      url: '/system/appraisal/importEvaluation?data=' + JSON.stringify(_data),
      method: 'post',
    });
  },

  insertEvaluationQuestion(_data) {
    //添加试题
    return request({
      url:
        '/system/appraisal/insertEvaluationQuestion?data=' +
        JSON.stringify(_data),
      method: 'post',
    });
  },

  questionDelete(_data) {
    //题库删除
    return request({
      url: '/system/appraisal/questionDelete?data=' + JSON.stringify(_data),
      method: 'post',
    });
  },

  questionUpdate(_data) {
    //题库添加 修改
    return request({
      url: '/system/appraisal/questionUpdate?data=' + JSON.stringify(_data),
      method: 'post',
    });
  },
};
