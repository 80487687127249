import request from './request.js'

export default {
    integralList(_data){  //兑换订单
        return request({
            url:'/system/account/integralList?data='+ JSON.stringify(_data),
            method:'get',
        })
    },
    
    batchDelIntegralGoods(_data){  //批量删除商品
        return request({
            url:'/system/integral/batchDelIntegralGoods?data='+ JSON.stringify(_data),
            method:'post',
        })
    },

    batchShelvesIntegralGoods(_data){  //批量（下）架商品
        return request({
            url:'/system/integral/batchShelvesIntegralGoods?data='+ JSON.stringify(_data),
            method:'post',
        })
    },

    createIntegralGoods(_data,html){  //发布商品
        return request({
            url:'/system/integral/createGoods?data='+ JSON.stringify(_data),
            method:'post',
            data: html
        })
    },

    integralGoodsList(_data){  //积分商城查询列表(含搜索)
        return request({
            url:'/system/integral/integralGoodsList?data='+ JSON.stringify(_data),
            method:'get',
        })
    },

    integralGoodsCategoryDelete(_data){  //积分商品分类删除
        return request({
            url:'/system/integral/integralGoodsCategoryDelete?data='+ JSON.stringify(_data),
            method:'get',
        })
    },

    integralGoodsCategoryPage(_data){  //积分商品分类列表
        return request({
            url:'/system/integral/integralGoodsCategoryPage?data='+ JSON.stringify(_data),
            method:'get',
        })
    },

    integralGoodsUpdateCategory(_data){  //分类添加修改
        return request({
            url:'/system/integral/integralGoodsUpdateCategory?data='+ JSON.stringify(_data),
            method:'get',
        })
    },

    initIntegralModelPage(){  //商品模型列表初始化
        return request({
            url:'/system/integral/initGoodModelPage',
            method:'get',
        })
    },

    initIntegralModelSpecPage(_data){  //初始化模型下的商品属性
        return request({
            url:'/system/integral/initGoodsModelSpecPage?data='+ JSON.stringify(_data),
            method:'get',
        })
    },

    createIntegralModel(_data){  //添加-修改商品模型
        return request({
            url:'/system/integral/createGoodsModel?data='+ JSON.stringify(_data),
            method:'post',
        })
    },

    removeIntegralType(_data){  //删除商品模型
        return request({
            url:'/system/integral/removeGoodsType?data='+ JSON.stringify(_data),
            method:'post',
        })
    },

    createIntegralEditSpec(_data){  //（添加-编辑-删除）属性规格
        return request({
            url:'/system/integral/createEditSpec?data='+ JSON.stringify(_data),
            method:'post',
        })
    },

    deleteIntegralSpec(_data){  //删除规格、属性
        return request({
            url:'/system/integral/deleteSpec?data='+ JSON.stringify(_data),
            method:'post',
        })
    },
}