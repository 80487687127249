import { createRouter, createWebHashHistory } from 'vue-router';

export const routes = [
  {
    path: '/login',
    name: 'login',
    meta: { notoken: true },
    component: () => import('@/views/login/login.vue'),
  },
  {
    path: '/password',
    name: 'password',
    meta: { notoken: true },
    notoken: true,
    component: () => import('@/views/login/password.vue'),
  },
  {
    path: '/',
    title: '数据统计',
    component: () => import('@/components/navbar.vue'),
    children: [
      {
        path: '/workbenches',
        name: 'workbenches',
        component: () => import('@/views/system/workbenches.vue'),
        meta: { titL: ['数据统计'], tab: '/workbenches', },
      },
    ],
  },
  {
    path: '/',
    title: '测评管理',
    component: () => import('@/components/navbar.vue'),
    children: [
      {
        path: '/assess',
        name: 'assess',
        component: () => import('@/views/assess/assess.vue'),
        meta: { titL: ['考试管理'], tab: '/assess' },
        keepAlive: true,
      },
      {
        path: '/practise',
        name: 'practise',
        component: () => import('@/views/assess/practise.vue'),
        meta: { titL: ['练习管理'], tab: '/practise' },
        keepAlive:true,
      },
      {
        path: '/cep',
        name: 'cep',
        component: () => import('@/views/assess/cep.vue'),
        meta: { titL: ['模拟测评'], tab: '/cep' },
        keepAlive:true,
      },
      {
        path: '/assessInfo/:id',
        name: 'assessInfo',
        component: () => import('@/views/assess/assessInfo.vue'),
        meta: { titL: ['考试详情'], tab: '/assess' },
      },

      {
        path: '/theme',
        name: 'theme',
        component: () => import('@/views/assess/theme.vue'),
        meta: { titL: ['题库管理'], tab: '/theme' },
      },
      {
        path: '/questionbank/:id',
        name: 'questionbank',
        component: () => import('@/views/assess/questionbank.vue'),
        meta: { titL: ['题目管理'], tab: '/theme' },
      },
      {
        path: '/classify',
        name: 'classify',
        component: () => import('@/views/assess/classify.vue'),
        meta: { titL: ['分类管理'], tab: '/classify' },
      },
    ],
  },


  {
    path: '/',
    title: '量表管理',
    component: () => import('@/components/navbar.vue'),
    children: [
      {
        path: '/testOrder',
        name: 'testOrder',
        component: () => import('@/views/test/order.vue'),
        meta: { titL: ['测评订单'], tab: '/testOrder' },
      },
      {
        path: '/testOrderInfo/:id',
        name: 'testOrderInfo',
        component: () => import('@/views/test/orderInfo.vue'),
        meta: { titL: ['测评订单详情'], tab: '/testOrder' },
      },
      {
        path: '/dimensionScore',
        name: 'dimensionScore',
        component: () => import('@/views/test/dimensionScore.vue'),
        meta: { titL: ['维度分值'], tab: '/test' },
      },
      {
        path: '/testClassify',
        name: 'testClassify',
        component: () => import('@/views/test/classify.vue'),
        meta: { titL: ['分类管理'], tab: '/testClassify' },
      },
      {
        path: '/test',
        name: 'test',
        component: () => import('@/views/test/test.vue'),
        meta: { titL: ['测评列表'], tab: '/test', keepAlive: true },
      },
      {
        path: '/testUser/:id',
        name: 'testUser',
        component: () => import('@/views/test/testUser.vue'),
        meta: { titL: ['测评用户'], tab: '/test' },
      },
      {
        path: '/dimensionAdd',
        name: 'dimensionAdd',
        component: () => import('@/views/test/dimensionAdd.vue'),
        meta: { titL: ['添加维度'], tab: '/test' },
      },
      {
        path: '/testInfo/:id',
        name: 'testInfo',
        component: () => import('@/views/test/testInfo.vue'),
        meta: { titL: ['测评详情'], tab: '/test' },
      },
      {
        path: '/testAdd',
        name: 'testAdd',
        component: () => import('@/views/test/testAdd.vue'),
        meta: { titL: ['添加测评'], tab: '/test' },
      },
      {
        path: '/question',
        name: 'question',
        component: () => import('@/views/test/question.vue'),
        meta: { titL: ['题库列表'], tab: '/question', keepAlive: true },
      },
      {
        path: '/questionAdd/:id',
        name: 'questionAdd',
        component: () => import('@/views/test/questionAdd.vue'),
        meta: { titL: ['添加测评试题'], tab: '/question' },
      },
      {
        path: '/questionInfo/:id',
        name: 'questionInfo',
        component: () => import('@/views/test/questionInfo.vue'),
        meta: { titL: ['题库详情'], tab: '/question' },
      },
    ],
  },

  {
    path: '/',
    title: '商户',
    component: () => import('@/components/navbar.vue'),
    children: [
      {
        path: '/merchant',
        name: 'merchant',
        component: () => import('@/views/merchant/index.vue'),
        meta: { titL: ['商户管理'], tab: '/merchant', keepAlive: true },
      },
      {
        path: '/merchantInfo/:id',
        name: 'merchantInfo',
        component: () => import('@/views/merchant/info.vue'),
        meta: { titL: ['商户详情'], tab: '/merchant' },
      },
      {
        path: '/settled',
        name: 'settled',
        component: () => import('@/views/merchant/settled.vue'),
        meta: { titL: ['入驻审核'], tab: '/settled', keepAlive: true },
      },
      {
        path: '/settledInfo/:id',
        name: 'settledInfo',
        component: () => import('@/views/merchant/settledInfo.vue'),
        meta: { titL: ['商户信息详情'], tab: '/settled' },
      },
    ],
  },

  {
    path: '/',
    title: '活动',
    component: () => import('@/components/navbar.vue'),
    children: [
      // {
      //   path: '/activitylist',
      //   name: 'activitylist',
      //   component: () => import('@/views/activity/activitylist.vue'),
      //   meta: { titL: ['活动列表'], tab: '/activitylist', keepAlive: true },
      // },
      // {
      //   path: '/addactivity',
      //   name: 'addactivity',
      //   component: () => import('@/views/activity/addactivity.vue'),
      //   meta: { titL: ['添加活动'], tab: '/activitylist', keepAlive: true },
      // },
      // {
      //   path: '/activitydetail',
      //   name: 'activitydetail',

    ],
  },
  {
    path: '/',
    title: '用户管理',
    component: () => import('@/components/navbar.vue'),
    children: [
      {
        path: '/users',
        name: 'users',
        component: () => import('@/views/users/index.vue'),
        meta: { titL: ['用户管理'], tab: '/users', keepAlive: true },
      },
      {
        path: '/userInfo/:id',
        name: 'userInfo',
        component: () => import('@/views/users/info.vue'),
        meta: { titL: ['用户管理'], tab: '/users' },
      },
    ],
  },
  {
    path: '/',
    title: '商品管理',
    component: () => import('@/components/navbar.vue'),
    children: [
      {
        path: '/course',
        name: 'course',
        component: () => import('@/views/goods/course.vue'),
        meta: { titL: ['课程管理'], tab: '/course', keepAlive: true },
      },
      {
        path: '/goodsManage',
        name: 'goodsManage',
        component: () => import('@/views/goods/goodsManage.vue'),
        meta: { titL: ['商品管理'], tab: '/goodsManage', keepAlive: true },
      },
      {
        path: '/goodsInfo/:id',
        name: 'goodsInfo',
        component: () => import('@/views/goods/goodsInfo.vue'),
        meta: { titL: ['商品管理', '商品详情'], tab: '/goodsManage' },
      },
      {
        path: '/integralGoods',
        name: 'integralGoods',
        component: () => import('@/views/goods/integralGoods.vue'),
        meta: {
          titL: ['积分商城商品'],
          tab: '/integralGoods',
          keepAlive: true,
        },
      },
      {
        path: '/integralGoodsAdd/:id',
        name: 'integralGoodsAdd',
        component: () => import('@/views/goods/integralGoodsAdd.vue'),
        meta: { titL: ['积分商城商品', '发布商品'], tab: '/integralGoods' },
      },
      {
        path: '/integralSpecification',
        name: 'integralSpecification',
        component: () => import('@/views/goods/integralSpecification.vue'),
        meta: {
          titL: ['积分商品规格'],
          tab: '/integralSpecification',
          keepAlive: true,
        },
      },
      {
        path: '/integralSpecificationManage/:id/:name',
        name: 'integralSpecificationManage',
        component: () =>
          import('@/views/goods/integralSpecificationManage.vue'),
        meta: { titL: ['积分商品规格管理'], tab: '/integralSpecification' },
      },
      {
        path: '/comboManage',
        name: 'comboManage',
        component: () => import('@/views/goods/comboManage.vue'),
        meta: { titL: ['套餐管理'], tab: '/comboManage', keepAlive: true },
      },
      {
        path: '/comboInfo',
        name: 'comboInfo',
        component: () => import('@/views/goods/comboInfo.vue'),
        meta: { titL: ['套餐管理', '套餐详情'], tab: '/comboManage' },
      },
    ],
  },
  {
    path: '/',
    title: '订单',
    component: () => import('@/components/navbar.vue'),
    children: [
      {
        path: '/courseOrder',
        name: 'courseOrder',
        component: () => import('@/views/order/courseOrder.vue'),
        meta: { titL: ['课程订单'], tab: '/courseOrder', keepAlive: true },
      },
      // {
      //   path: '/courseOrderInfo/:id',
      //   name: 'courseOrderInfo',
      //   component: () => import('@/views/order/courseOrderInfo.vue'),
      //   meta: { titL: ['课程订单', '订单详情'], tab: 'courseOrder' },
      // },
      {
        path: '/goodsOrder',
        name: 'goodsOrder',
        component: () => import('@/views/order/goodsOrder.vue'),
        meta: { titL: ['商品订单'], tab: '/goodsOrder', keepAlive: true },
      },
      {
        path: '/goodsOrderInfo/:id',
        name: 'goodsOrderInfo',
        component: () => import('@/views/order/goodsOrderInfo.vue'),
        meta: { titL: ['商品订单', '订单详情'], tab: '/courseOrder' },
      },
      {
        path: '/goodsRefundInfo/:id',
        name: 'goodsRefundInfo',
        component: () => import('@/views/order/goodsRefundInfo.vue'),
        meta: { titL: ['商品订单', '退款订单详情'], tab: '/goodsOrder' },
      },

      {
        path: '/goodsLogistics/:id',
        name: 'goodsLogistics',
        component: () => import('@/views/order/goodsLogistics.vue'),
        meta: {
          titL: ['商品订单', '订单详情', '物流'],
          tab: '/goodsOrder',
          tabType: 'old',
        },
      },
      {
        path: '/comboOrder',
        name: 'comboOrder',
        component: () => import('@/views/order/comboOrder.vue'),
        meta: { titL: ['套餐订单'], tab: '/comboOrder', keepAlive: true },
      },
      {
        path: '/comboOrderInfo/:id',
        name: 'comboOrderInfo',
        component: () => import('@/views/order/comboOrderInfo.vue'),
        meta: { titL: ['套餐订单', '订单详情'], tab: '/comboOrder' },
      },
      {
        path: '/groupOrder',
        name: 'groupOrder',
        component: () => import('@/views/order/groupOrder.vue'),
        meta: { titL: ['拼团订单'], tab: '/groupOrder', keepAlive: true },
      },
      {
        path: '/groupOrderInfo/:id',
        name: 'groupOrderInfo',
        component: () => import('@/views/order/groupOrderInfo.vue'),
        meta: { titL: ['拼团订单', '订单详情'], tab: '/groupOrder' },
      },
    ],
  },
  {
    path: '/',
    title: '营销',
    component: () => import('@/components/navbar.vue'),
    children: [
      {
        path: '/group',
        name: 'group',
        component: () => import('@/views/marketing/group.vue'),
        meta: { titL: ['拼团'], tab: '/group', keepAlive: true },
      },
      {
        path: '/groupAdd',
        name: 'groupAdd',
        component: () => import('@/views/marketing/groupAdd.vue'),
        meta: { titL: ['添加拼团'], tab: '/group' },
      },
      {
        path: '/groupInfo/:id',
        name: 'groupInfo',
        component: () => import('@/views/marketing/groupInfo.vue'),
        meta: { titL: ['拼团', '拼团详情'], tab: '/group' },
      },
    ],
  },
  {
    path: '/',
    title: '店铺优惠券',
    component: () => import('@/components/navbar.vue'),
    children: [
      {
        path: '/shopECommerce',
        name: 'shopECommerce',
        component: () => import('@/views/shopCoupon/eCommerce.vue'),
        meta: { titL: ['电商优惠券'], tab: '/shopECommerce', keepAlive: true },
      },
      {
        path: '/shopService',
        name: 'shopService',
        component: () => import('@/views/shopCoupon/service.vue'),
        meta: { titL: ['服务优惠券'], tab: '/shopService', keepAlive: true },
      },
      {
        path: '/couponInfo/:id/:type',
        name: 'couponInfo',
        component: () => import('@/views/shopCoupon/info.vue'),
        meta: { titL: ['优惠券详情'], tab: '/shopECommerce', tabType: 'old' },
      },
    ],
  },
  {
    path: '/',
    title: '平台优惠券',
    component: () => import('@/components/navbar.vue'),
    children: [
      {
        path: '/platformCourse',
        name: 'platformCourse',
        component: () => import('@/views/platformCoupon/course.vue'),
        meta: { titL: ['图书优惠券'], tab: '/platformCourse', keepAlive: true },
      },
      {
        path: '/platformECommerce',
        name: 'platformECommerce',
        component: () => import('@/views/platformCoupon/eCommerce.vue'),
        meta: {
          titL: ['课程优惠券'],
          tab: '/platformECommerce',
          keepAlive: true,
        },
      },
      {
        path: '/platformService',
        name: 'platformService',
        component: () => import('@/views/platformCoupon/service.vue'),
        meta: {
          titL: ['服务优惠券'],
          tab: '/platformService',
          keepAlive: true,
        },
      },
      {
        path: '/addCoupon/:type',
        name: 'addCoupon',
        component: () => import('@/views/platformCoupon/add.vue'),
        meta: {
          titL: ['添加优惠券'],
          tab: '/platformECommerce',
          tabType: 'old',
        },
      },
    ],
  },
  {
    path: '/',
    title: '评论',
    component: () => import('@/components/navbar.vue'),
    children: [
      {
        path: '/courseComment',
        name: 'courseComment',
        component: () => import('@/views/comment/course.vue'),
        meta: { titL: ['课程评论'], tab: '/courseComment' },
      },
      {
        path: '/goodsComment',
        name: 'goodsComment',
        component: () => import('@/views/comment/goodsComment.vue'),
        meta: { titL: ['商品评论'], tab: '/goodsComment' },
      },
      {
        path: '/comboComment',
        name: 'comboComment',
        component: () => import('@/views/comment/comboComment.vue'),
        meta: { titL: ['套餐评论'], tab: '/comboComment' },
      },
    ],
  },
  {
    path: '/',
    title: '消息',
    component: () => import('@/components/navbar.vue'),
    children: [
      {
        path: '/systemNews',
        name: 'systemNews',
        component: () => import('@/views/news/systemNews.vue'),
        meta: { titL: ['系统消息'], tab: '/systemNews' },
      },
      {
        path: '/systemNewsInfo',
        name: 'systemNewsInfo',
        component: () => import('@/views/news/systemNewsInfo.vue'),
        meta: { titL: ['系统消息', '详情'], tab: '/systemNews' },
      },
      {
        path: '/issue',
        name: 'issue',
        component: () => import('@/views/news/issue.vue'),
        meta: { titL: ['发布消息'], tab: '/systemNews' },
      },
      {
        path: '/eCommerceNews',
        name: 'eCommerceNews',
        component: () => import('@/views/news/eCommerceNews.vue'),
        meta: {
          titL: ['电商推送消息'],
          tab: '/eCommerceNews',
          keepAlive: true,
        },
      },
    ],
  },
  {
    path: '/',
    title: '分类',
    component: () => import('@/components/navbar.vue'),
    children: [
      {
        path: '/courseClassify',
        name: 'courseClassify',
        component: () => import('@/views/classify/courseClassify.vue'),
        meta: {
          titL: ['机构课程分类'],
          tab: '/courseClassify',
          keepAlive: true,
        },
      },
      {
        path: '/goodsClassify',
        name: 'goodsClassify',
        component: () => import('@/views/classify/goodsClassify.vue'),
        meta: { titL: ['商品分类'], tab: '/goodsClassify', keepAlive: true },
      },
      {
        path: '/integralClassify',
        name: 'integralClassify',
        component: () => import('@/views/classify/integralClassify.vue'),
        meta: {
          titL: ['积分商城分类'],
          tab: '/integralClassify',
          keepAlive: true,
        },
      },
      {
        path: '/comboClassify',
        name: 'comboClassify',
        component: () => import('@/views/classify/comboClassify.vue'),
        meta: {
          titL: ['本地生活分类'],
          tab: '/comboClassify',
          keepAlive: true,
        },
      },
    ],
  },
  {
    path: '/',
    component: () => import('@/components/navbar.vue'),
    children: [
      {
        path: '/finance',
        name: 'finance',
        component: () => import('@/views/finance/index.vue'),
        meta: { titL: ['财务明细'], tab: '/finance', keepAlive: true },
      },
      {
        path: '/userWithdraw',
        name: 'userWithdraw',
        component: () => import('@/views/finance/userWithdraw.vue'),
        meta: { titL: ['用户提现审核'], tab: '/userWithdraw', keepAlive: true },
      },
      {
        path: '/shopWithdraw',
        name: 'shopWithdraw',
        component: () => import('@/views/finance/shopWithdraw.vue'),
        meta: { titL: ['店铺提现审核'], tab: '/shopWithdraw', keepAlive: true },
      },
    ],
  },

  {
    path: '/',
    component: () => import('@/components/navbar.vue'),
    children: [],
  },
  {
    path: '/',
    title: '设置',
    component: () => import('@/components/navbar.vue'),
    children: [
      {
        path: '/aboutUs',
        name: 'aboutUs',
        component: () => import('@/views/set/aboutUs.vue'),
        meta: { titL: ['关于我们'], tab: '/aboutUs' },
      },
      {
        path: '/pcAboutUs',
        name: 'pcAboutUs',
        component: () => import('@/views/set/pcAboutUs.vue'),
        meta: { titL: ['pc关于我们'], tab: '/pcAboutUs' },
      },
      {
        path: '/pcContactsUs',
        name: 'pcContactsUs',
        component: () => import('@/views/set/pcContactsUs.vue'),
        meta: { titL: ['pc联系我们'], tab: '/pcContactsUs' },
      },
      {
        path: '/identity',
        name: 'identity',
        component: () => import('@/views/set/identity.vue'),
        meta: { titL: ['身份管理'], tab: '/identity', keepAlive: true },
      },
      {
        path: '/manager',
        name: 'manager',
        component: () => import('@/views/set/manager.vue'),
        meta: { titL: ['管理员管理'], tab: '/manager', keepAlive: true },
      },
      {
        path: '/advertisement',
        name: 'advertisement',
        component: () => import('@/views/set/advertisement.vue'),
        meta: { titL: ['广告设置'], tab: '/advertisement', keepAlive: true },
      },
      {
        path: '/pear',
        name: 'pear',
        component: () => import('@/views/set/pear.vue'),
        meta: { titL: ['战略合作'], tab: '/pear', keepAlive: true },
      },



      {
        path: '/operateProvince',
        name: 'operateProvince',
        component: () => import('@/views/set/operateProvince.vue'),
        meta: {
          titL: ['运营城市设置'],
          tab: '/operateProvince',
          keepAlive: true,
        },
      },

      {
        path: '/operationLog',
        name: 'operationLog',
        component: () => import('@/views/set/operationLog.vue'),
        meta: { titL: ['操作日志'], tab: '/operationLog', keepAlive: true },
      },
      {
        path: '/appVersion',
        name: 'appVersion',
        component: () => import('@/views/set/appVersion.vue'),
        meta: { titL: ['APP版本管理'], tab: '/appVersion', keepAlive: true },
      },
      {
        path: '/xieyi',
        name: 'xieyi',
        component: () => import('@/views/set/xieyi.vue'),
        meta: { titL: ['用户协议'], tab: '/xieyi', keepAlive: true },
      },
      {
        path: '/articleEditxiyi/:id',
        name: 'articleEditxiyi',
        component: () => import('@/views/set/articleEditxiyi.vue'),
        meta: { titL: ['编辑协议'], tab: '/articleEditxiyi' },
      },
      {
        path: '/article',
        name: 'article',
        component: () => import('@/views/set/article.vue'),
        meta: { titL: ['帮组中心'], tab: '/article', keepAlive: true },
      },
      {
        path: '/feecate',
        name: 'feecate',
        component: () => import('@/views/set/feecate.vue'),
        meta: { titL: ['帮组分类'], tab: '/feecate' },
      },
      {
        path: '/articleEdit/:id',
        name: 'articleEdit',
        component: () => import('@/views/set/articleEdit.vue'),
        meta: { titL: ['编辑文章'], tab: '/article' },
      },
      {
        path: '/paySet',
        name: 'paySet',
        component: () => import('@/views/set/paySet.vue'),
        meta: { titL: ['支付设置'], tab: '/paySet', keepAlive: true },
      },
      {
        path: '/payCollocation',
        name: 'payCollocation',
        component: () => import('@/views/set/payCollocation.vue'),
        meta: { titL: ['支付配置'], tab: '/payCollocation', keepAlive: true },
      },
    ],
  },

  {
    path: '/',
    title: '图书管理',
    component: () => import('@/components/navbar.vue'),
    children: [
      {
        path: '/bookList',
        name: 'bookList',
        component: () => import('@/views/tushu/bookList.vue'),
        meta: { titL: ['图书列表'], tab: '/bookList', keepAlive: true },
      },
      {
        path: '/tushuAdd',
        name: 'tushuAdd',
        component: () => import('@/views/tushu/tushuAdd.vue'),
        meta: { titL: ['图书列表', '添加图书'], tab: '/tushuAdd' },
      },
      {
        path: '/bookAddguige',
        name: 'bookAddguige',
        component: () => import('@/views/tushu/bookAddguige.vue'),
        meta: { titL: ['图书列表', '图书规格'], tab: '/bookAddguige' },
      },


      {
        path: '/bookCata',
        name: 'bookCata',
        component: () => import('@/views/tushu/bookCata.vue'),
        meta: { titL: ['图书分类'], tab: '/bookCata', keepAlive: true },
      },
      {
        path: '/zhengshu',
        name: 'zhengshu',
        component: () => import('@/views/tushu/zhengshu.vue'),
        meta: { titL: ['证书列表'], tab: '/zhengshu', keepAlive: true },
      },

      {
        path: '/zhengshuinfo',
        name: 'zhengshuinfo',
        component: () => import('@/views/tushu/zhengshuinfo.vue'),
        meta: { titL: ['证书详情'], tab: '/zhengshu' },
      },
    ],
  },

  {
    path: '/',
    title: '学习资料管理',
    component: () => import('@/components/navbar.vue'),
    children: [
      {
        path: '/learningMaterialsList',
        name: 'learningMaterialsList',
        component: () => import('@/views/learningMaterials/learningMaterialsList.vue'),
        meta: { titL: ['学习资料列表'], tab: '/learningMaterialsList', keepAlive: true },
      },
      {
        path: '/ziliaoAdd',
        name: 'ziliaoAdd',
        component: () => import('@/views/learningMaterials/ziliaoAdd.vue'),
        meta: { titL: ['课程列表', '添加资料'], tab: '/ziliaoAdd' },
      },


      {
        path: '/learningMaterialsCategoryList',
        name: 'learningMaterialsCategoryList',
        component: () => import('@/views/learningMaterials/learningMaterialsCategoryList.vue'),
        meta: { titL: ['学习资料分类列表'], tab: '/learningMaterialsCategoryList', keepAlive: true },
      },


    ],
  },

  {
    path: '/',
    title: '经销商管理',
    component: () => import('@/components/navbar.vue'),
    children: [
      {
        path: '/jingxiaoshang',
        name: 'jingxiaoshang',
        component: () => import('@/views/jingxiaoshang/jingxiaoshang.vue'),
        meta: { titL: ['招生合作单位'], tab: '/jingxiaoshang', keepAlive: true },
      },

      {
        path: '/courseshang',
        name: 'courseshang',
        component: () => import('@/views/jingxiaoshang/courseshang.vue'),
        meta: { titL: ['课程合作单位'], tab: '/courseshang', keepAlive: true },
      },
      
      {
        path: '/jingxiaoshanginfo/:id',
        name: 'jingxiaoshanginfo',
        component: () => import('@/views/jingxiaoshang/info.vue'),
        meta: { titL: ['招生合作订单列表'], tab: '/jingxiaoshang' },
      },
      {
        path: '/jingxiaoshanguserinfo/:id',
        name: 'jingxiaoshanguserinfo',
        component: () => import('@/views/jingxiaoshang/info1.vue'),
        meta: { titL: ['经销商列表'], tab: '/jingxiaoshang', keepAlive: true },
      },

      {
        path: '/kechenghezuoinfo/:id',
        name: 'kechenghezuoinfo',
        component: () => import('@/views/jingxiaoshang/kechenghezuoinfo.vue'),
        meta: { titL: ['课程合作规格列表'], tab: '/courseshang' },
      },
      {
        path: '/kechenghezuoorder/:id',
        name: 'kechenghekechenghezuoorderzuoinfo',
        component: () => import('@/views/jingxiaoshang/kechenghezuoorder.vue'),
        meta: { titL: ['课程合作规格订单列表'], tab: '/courseshang' },
      },

    ],
  },



  {
    path: '/',
    title: '发票管理',
    component: () => import('@/components/navbar.vue'),
    children: [
      {
        path: '/invoice',
        name: 'invoice',
        component: () => import('@/single/finance/invoice.vue'),
        meta: { titL: ['发票列表'], tab: '/invoice', keepAlive: true },
      },
      {
        path: '/invoiceSingle',
        name: 'invoiceSingle',
        component: () => import('@/single/finance/invoice.vue'),
        meta: { titL: ['发票管理'], tab: '/invoiceSingle', keepAlive: true },
      },

    ],
  },
  {
    path: '/',
    title: '图文文章',
    component: () => import('@/components/navbar.vue'),
    children: [
      {
        path: '/essay',
        name: 'essay',
        component: () => import('@/views/essay/essay.vue'),
        meta: { titL: ['资讯列表'], tab: '/essay' },
      },
      {
        path: '/addessay/:id',
        name: 'addessay',
        component: () => import('@/views/essay/addessay.vue'),
        meta: { titL: ['添加文章'], tab: '/essay' },
      },
      {
        path: '/essaytype',
        name: 'essaytype',
        component: () => import('@/views/essay/essaytype.vue'),
        meta: { titL: ['分类管理'], tab: '/essaytype' },
      },
      { 
        path: "/help", 
        name: 'help',
        component: () => import('@/views/set/help.vue'),
        meta:{ titL: ['帮助反馈'] , tab:'/help'  },
      },
    ],
  },



  {
    path: '/',
    title: '单商户设置',
    component: () => import('@/components/navbar.vue'),
    children: [
      {
        path: '/teacherSingle',
        name: 'teacherSingle',
        component: () => import('@/single/teacher/index.vue'),
        meta: { titL: ['老师列表'], tab: '/teacherSingle' },
      },
      {
        path: '/teacherAddSingle',
        name: 'teacherAddSingle',
        component: () => import('@/single/teacher/add.vue'),
        meta: { titL: ['老师列表', '添加老师'], tab: '/teacherSingle' },
      },
      {
        path: '/teacherInfoSingle',
        name: 'teacherInfoSingle',
        component: () => import('@/single/teacher/info.vue'),
        meta: { titL: ['老师列表', '老师详情'], tab: '/teacherSingle' },
      },
      {
        path: '/recordAddSingle',
        name: 'recordAddSingle',
        component: () => import('@/single/course/recordAdd.vue'),
        meta: { titL: ['课程列表', '添加课程'], tab: '/recordSingle' },
      },
      {
        path: '/recordChapAddSingle',
        name: 'recordChapAddSingle',
        component: () => import('@/single/course/recordChapAdd.vue'),
        meta: { titL: ['课程列表', '添加课程章节'], tab: '/recordSingle' },
      },
      {
        path: '/recordAddguige',
        name: 'recordAddguige',
        component: () => import('@/single/course/recordAddguige.vue'),
        meta: { titL: ['课程列表', '添加课程规格'], tab: '/recordAddguige' },
      },
      {
        path: '/recordSingle',
        name: 'recordSingle',
        component: () => import('@/single/course/record.vue'),
        meta: { titL: ['课程列表'], tab: '/recordSingle' },
      },
      {
        path: '/recordAddtu',
        name: 'recordAddtu',
        component: () => import('@/single/course/recordAddtu.vue'),
        meta: { titL: ['课程列表', '添加tu'], tab: '/recordAddtu' },
      },
      {
        path: '/label',
        name: 'label',
        component: () => import('@/single/course/label.vue'),
        meta: { titL: ['课程标签'], tab: '/label' },
      },

      {
        path: '/yijiankechenglist',
        name: 'yijiankechenglist',
        component: () => import('@/single/course/yijiankechenglist.vue'),
        meta: { titL: ['一建课程列表'], tab: '/recordSingle' },
      },


      {
        path: '/accountSingle',
        name: 'accountSingle',
        component: () => import('@/single/finance/account.vue'),
        meta: {
          titL: ['提现账户设置'],
          tab: '/accountSingle',
          keepAlive: true,
        },
      },
      {
        path: '/financeSingle',
        name: 'financeSingle',
        component: () => import('@/single/finance/finance.vue'),
        meta: { titL: ['财务明细'], tab: '/financeSingle', keepAlive: true },
      },
      {
        path: '/freightTemplateSingle',
        name: 'freightTemplateSingle',
        component: () => import('@/single/goods/freightTemplate.vue'),
        meta: {
          titL: ['运费模板'],
          tab: '/freightTemplateSingle',
          keepAlive: true,
        },
      },
      {
        path: '/goodsSingle',
        name: 'goodsSingle',
        component: () => import('@/single/goods/goodsManage.vue'),
        meta: { titL: ['商品管理'], tab: '/goodsSingle', keepAlive: true },
      },
      {
        path: '/goodsOrderSingle',
        name: 'goodsOrderSingle',
        component: () => import('@/single/order/goodsOrder.vue'),
        meta: { titL: ['商品订单'], tab: '/goodsOrderSingle', keepAlive: true },
      },
      {
        path: '/goodsOrderInfoSingle/:id',
        name: 'goodsOrderInfoSingle',
        component: () => import('@/single/order/goodsOrderInfo.vue'),
        meta: { titL: ['商品订单详情'], tab: '/goodsOrderSingle' },
      },
      {
        path: '/goodsRefundInfoSingle/:id',
        name: 'goodsRefundInfoSingle',
        component: () => import('@/single/order/goodsRefundInfo.vue'),
        meta: { titL: ['退款详情'], tab: '/refundSingle' },
      },
      {
        path: '/goodsSpecificationSingle',
        name: 'goodsSpecificationSingle',
        component: () => import('@/single/goods/goodsSpecification.vue'),
        meta: {
          titL: ['商品规格'],
          tab: '/goodsSpecificationSingle',
          keepAlive: true,
        },
      },
      {
        path: '/goodsSpecificationManageSingle/:id/:name',
        name: 'goodsSpecificationManageSingle',
        component: () => import('@/single/goods/goodsSpecificationManage.vue'),
        meta: { titL: ['规格管理'], tab: '/goodsSpecificationSingle' },
      },

      {
        path: '/refundSingle',
        name: 'refundSingle',
        component: () => import('@/single/order/refund.vue'),
        meta: { titL: ['退款订单'], tab: '/refundSingle', keepAlive: true },
      },
      {
        path: '/releaseSingle/:id',
        name: 'releaseSingle',
        component: () => import('@/single/goods/release.vue'),
        meta: { titL: ['商品管理', '发布商品'], tab: '/goodsSingle' },
      },
      {
        path: '/seckillSingle',
        name: 'seckillSingle',
        component: () => import('@/single/marketing/seckill.vue'),
        meta: { titL: ['秒杀管理'], tab: '/seckillSingle', keepAlive: true },
      },
      {
        path: '/seckillAddSingle/:id',
        name: 'seckillAddSingle',
        component: () => import('@/single/marketing/seckillAdd.vue'),
        meta: { titL: ['秒杀管理', '添加秒杀商品'], tab: '/seckillSingle' },
      },
    ],
  },
  // Single
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

export default router;
